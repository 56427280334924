<template>
    <KCourse loader-class="MBcont" course-id="8" title="Будуарная кукла" :items="items">
        <div class="MBtextcont">
            <h3>1. Устанавливаем перемычки в тело</h3>
        </div>

        <VideoView video-id="cbbbefeee9dc4dfaabddd4ae9c1da2d5"/>

        <div class="MBtextcont">
            <p class="nomargin"><b>Что нужно сделать:</b></p>
            <ol>
                <li>Симметрично установить крючки в плечи</li>
                <li>Установить перемычку внутрь тела.</li>
            </ol>
            
            <h3>2. Устанавливаем перемычку в голову</h3>
        </div>

        <VideoView video-id="bc11edaf29d94d1fb20aabd78fc84ee7"/>

        <div class="MBtextcont">
            <p class="nomargin"><b>Что нужно сделать:</b></p>
            <ol>
                <li>Шкурим ухо</li>
                <li>Дорабатываем шарнироприемник</li>
                <li>Устанавливаем перемычку в голову</li>
                <li>Делаем уступчик по линии роста волос</li>
            </ol>

            <h3>3. Подготовим детали ног и рук</h3>
        </div>
        
        <VideoView video-id="4d5935f278444139a1a6b0b47cb5baed"/>        

        <div class="MBtextcont">
            <p class="nomargin"><b>Что нужно сделать:</b></p>
            <ol>
                <li>На ногах и руках отмечаем место установки перемычки (5мм вниз от уступа). Проверяем параллельность и симметричность точек!</li>
                <li>Углубляем и расширяем отверстие под шарниром, зашкуриваем.</li>
                <li>Прорезаем отверстие для перемычки с помощью ручного сверла или мини-дрели под прямым углом! Если все правильно, то проволока будет ровно проходить через оба отверстия.</li>
                <li>Расширяем шарнироприемники с помощью следка и бусины.</li>
            </ol>

            <h3>4. Делаем перемычки и крючки для кистей, стоп</h3>
        </div> 

        <VideoView video-id="8bf5e0ef40574fd3bbfa717d6a4dc380"/>

        <div class="MBtextcont">
            <p class="nomargin"><b>Что нужно сделать:</b></p>
            <ol>
                <li>Восстанавливаем разметку на шариках</li>
                <li>Намечаем прорезь по центру шарика в 2-2,5мм шириной от одного горизонтального центра до другого.</li>
                <li>Намечаем отверстие для перемычек чуть выше горизонтального центра на 1,5мм. Проверяем параллельность и симметричность точек!</li>
                <li>Пропиливаем прорезь напильником или мини-дрелью и выравниваем с помощью плоского надфиля.</li>
                <li>Сверлим отверстия для перемычек по бокам шарика. Если все правильно, то проволока будет ровно проходить через оба отверстия. Так же проверьте, чтобы проволока легко проходила под перемычкой. </li>
                <li>Закручиваем 4 крючка. Проверьте, чтобы резинка легко входила в разомкнутый конец крючка. Также проврьте, что крючок легко входит в отверстие руки или ноги.</li>
                <li>Проверяем всю конструкцию. Вставляем перемычку до середины, насаживаем крючок, продергиваем перемычку до конца. Пробуем "поболтать" стопой или кистью, если получается хорошо, если деталь не двигается свободно, то нужно углубить пространство под перемычкой, чтобы крючок легко двигался.</li>
                <li>Отрезаем перемычки по ширине детали (ноги, руки, кисти, стопы) так, чтобы кончики не выходили за края.</li>
            </ol>

            <a href="/files/BodoirV2/Bars.pdf" target="_blank" class="checklist" :class="$mq">
                <div class="checklist-img" :class="$mq">
                    <div class="checklist-icon">
                        <KImage :src="require('@/assets/membersh/bodoirV2/checklist-button-bars.png')" alt="" width="100%"/>
                    </div>
                </div>
                <div class="checklist-text" :class="$mq">
                    <h3>Скачать чек-лист в картинках</h3>
                    <p>Его можно распечатать, идти четко по пунткам и тем самым разобрать свою заготовку</p>
                </div>
            </a>

            <h3>5. Вклеиваем перемычки</h3>
        </div>
                
        <VideoView video-id="25a68f5b60ca4e5a8635edb75aeeae36"/>==

        <div class="MBtextcont">
            <h3 class="nomargin">Итого, что нужно сделать:</h3>
            <ol>
                <li>Расширить шарнироприемники</li>
                <li>Сделать уступ на голове</li>
                <li>Установить перемычки в:</li>
                <ol type="a">
                    <li>голову,</li>
                    <li>тело,</li>
                    <li>руки, ноги,</li>
                    <li>кисти, стопы</li>
                </ol>
            </ol>
           <h3>Время выполнения: 3 часа</h3>
        </div>
    </KCourse>
</template>

<script>
    import VideoView from "../../Widgets/VideoView";
    import KCourse from "../../Widgets/KCourse";
    import chapters from './data.js';
    import KImage from "../../Widgets/KImage";

    export default {
        name: "MBCourseHead",
        components: {
            VideoView,
            KCourse,
            KImage
        },
        data() {
        return {
            items: chapters
        }
        },
        methods: {
            showImage(src, customClass) {
                this.$swal.fire({
                    customClass: customClass,
                    background: '#ffffff',
                    html: '<img width="100%" src="'+src+'"/>',
                    showCloseButton: true,
                    showConfirmButton: false,
                    // closeButtonHtml: "",
                })
            },
        }
    }
</script>

<style>

</style>